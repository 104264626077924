/**
* Positionings
**/
.AboutParent{
    width: 100%;
    height: 100%;

    background-color: #2b3034;

    overflow: auto;
    
}

.MainColumn{
    width: 80%;

    margin-left: 10%;

    padding-left: 10%;
    padding-right: 10%;

    display: grid;
    
    grid-template-columns: 2fr 0.5fr;
    grid-template-rows: auto;
}

.CardItemsParent{
    position: fixed;

    display: flex;
    

    padding-left: 1%;
}


.InfoTextDiv{
    padding-left: 5%;
    padding-right: 5%;

    color: white;
}


/**
* Index Items styling
**/
.UnActiveListItem{
    color: #454d54;
}

.ActiveListItem{
    color: white;
    font-size: 1.1rem;

    transition: all 0.1s linear;
}

/**
* Text Div
**/
.TextDivTitle{
    text-align: center;
    margin-top: 1rem;   
    margin-bottom: 1rem;   
}

.Headers{
    font-size: 2.5rem;
}

.HeaderLineDivider{
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    border-color: #212529;
}

.SubHeader{
    font-size: 1.7rem;
    line-height: 2.5rem;

    margin-bottom: 0;
    margin-top: 3%;

}

.TextBlock{
    margin-top: 5%;
    height: auto;
    color: white;
}

.TextBlock div{
    font-weight: 15px;
}

.ParagraphBlock{
    margin-top: 3%;
}


/**
* How To Instructions
**/
.Instruction{
    margin-top: 1rem;
}

.InstructionHeader{
    margin-top: 2rem;
}


.FirstInstruction{
    margin-top: 0rem;
}

.InstructionImage{
    width: 98%;
    height: auto;

    border-radius: calc(.5rem - 1px);

    margin: 1%;
}



/**
* Right side info cards
**/
.CardLeftBorder{
    height: 80%;

    margin-top: 10%;

    border-left: 1px solid rgba(0, 0, 0, 0.2);
}

.CardItems{
    height: 100%;
    display: grid;

    grid-template-columns: auto;
    grid-template-rows: 1fr 2fr 1fr;

}

.CardHeaderText{
    font-weight: 500;
    font-size: 1.5rem;

    color: white;

    user-select: none;
}

.IndexCard{
    width: 100%;
    height: auto;

    margin-top: 30px;
 
    color: white;
}

.IndexBody{
    padding-top: 0.5rem;
}


.IndexLineBreak{
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    
    border-color: rgba(0, 0, 0, 0.2);
}

/**
* Lobby Code
**/
.LobbyCode{
    margin-top: 10%;
}

.LobbyCodeBody{
    background-color: #31363d;

    padding: 0;

    text-align: center;
}


.CodeSubtitle{
    font-weight: 100;
    font-size: 16px;
    user-select: none;
}

.Code{
    padding-bottom: 1rem;

    font-size: 1.5rem;
    line-height: 1.5rem;

    color: white;
}

.CodeTitle{
    font-weight: 100;
}





/**
* Other Lnks section
**/

.Links{
    color: white;
    font-weight: 100;
 
}

.Links:hover{
    text-decoration: underline;
    color: white;
}



/**
* API Documentation Table
**/

.APIDocumentationTable{
    width: 100%;
    height: auto;

    border: 1px solid #181818;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;

    padding: 1rem;  
}

.APIDocumentationTable tr, .APIDocumentationTable td{
    padding: 0.25rem;  
    border: inherit;
}




/**
* Stupid Mobile Design
**/
@media only screen and (max-width: 600px){
    /**Update App Root**/
    .AppFrame{
        display: block;
    }

    .AppBody{
        width: 100%;
        height: calc(100% - 60px);
    }

    /**
    * Menu Config
    **/
    .MenuBar{
        width: 100%;
        height: 60px;

        display: grid;
        grid-template-columns: 1fr 2fr 1fr;
        grid-template-rows: auto;
    }

    .CardItemsParent{
        position: relative;
    }

    .MenuItemGroups{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: auto;
    }

    .MenuButton{
        margin-top: 0;
    }
    
    .MenuDivider{
        display: none;
    }

    .DiscordLogo{
        margin-top: 15px;
    }

    .AlertIcon{
        right: 0;
        position: relative;
        display: none;

    }



    /** 
    * Info Section
    **/
    .MainColumn{
        width: 100%;

        margin-left: 0;

        padding-left: 0;
        padding-right: 0;

        display: grid;

        grid-template-columns: 2fr 0.5fr;
        grid-template-rows: auto;
    }


    .TextDivTitle{
        font-size: 2.4rem;
    }

    .Headers{
        font-size: 2.3rem;
    }

}