.DisplayParent{
    width: 100%;
    height: 100%;
}


.DisplayNavBar{
    width: 100%;
    height: auto;

    padding: 0;

    background-color: rgba(0, 0, 0, 0.05);

    border-bottom: 1px #343a40 solid;

    justify-content: start;
}

.DataDisplayDiv{
    width: 96%;
    height: 85%;

    margin-left: 2%;
    margin-top: 1%;

    overflow: auto;

    border: 1px solid #343a40;
    border-radius: 5px;
}