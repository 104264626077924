.data-grid-container .data-grid {
    table-layout: auto;
    border-collapse: collapse;
} 




.data-grid-container .data-grid .cell,
.data-grid-container .data-grid.wrap .cell,
.data-grid-container .data-grid.wrap .cell.wrap,
.data-grid-container .data-grid .cell.wrap,
.data-grid-container .data-grid.nowrap .cell.wrap,
.data-grid-container .data-grid.clip .cell.wrap {
  white-space: nowrap;
  padding: 5px;
  padding-left: 0.25em;
  text-align: left;
  font-size: 14px;
}

.cell.read-only{
    color: black; 
    font-size: 20px;
}


.data-grid-container .data-grid .cell > input {
  width: 100%;
  height: 100%;

  outline: none !important;
  border: 2px solid rgb(33, 133, 208);
  text-align: left;
  background: none;
  display: block;
}


.data-grid-container .data-grid .cell {
  border: 1px solid #343a40;
}