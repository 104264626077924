.FileComponent{
    display: grid;
    grid-template-columns: 2fr 1fr 2fr;
    

    padding-top: 0.5rem;
    padding-left: 0.5rem;
    padding-bottom: 0.5rem;

    text-align: left;

}

.FileComponentName{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}



.FileComponentHeader{

    display: grid;
    grid-template-columns: 2fr 1fr 2fr;

    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    text-align: left;

}