/**
* Pane Stylings
*   Refers to the panes displayed on page in the [1, 2] format
**/

/**
* Parsation Styling
*   Input & Output boxes
**/

.ParsationPane{
    width: 100%;
    height: 94%;

    display: flex;
}


.WorkshopInput{
    width: 47.5%;
    height: 95%;

    margin-top: 1%;
    margin-left: 1.25%;
    margin-right: 1.25%

}


.ServerOutput{
    width: 47.5%;
    height: 95%;


    margin-top: 1%;
    margin-left: 1.25%;
    margin-right: 1.25%

}

