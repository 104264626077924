.GameInfoDiv{
    width: 100%;
    height: 100%;

    padding-left: 5%;
    padding-top: 2.5%;

    color: white;
    background-color: #3E454D;
}

.GameInfoPageTitle{
    margin-left: -2.5%;
    margin-bottom: -4%;
}

.InputBox{
    width: 90%;
    margin-top: 5%;
}

.GameDataTitle{
    margin: 0;
    padding: 0;
}

.GameDataInput{
    width: 100%;
    height: calc(1.5em + .75rem + 2px);

    display: block;
    
    border: 1px solid rgba(255, 255, 255, 0.3);

    border-top: 0px solid transparent;
    border-left: 0px solid transparent;
    border-right: 0px solid transparent;

    border-radius: 0px;

    padding: .375rem .75rem;

    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: white;

    background-color: transparent;
    background-clip: padding-box;

    outline: 0px solid transparent;


}

.GameDataInput:focus, .GameDataInput:active{
    width: 100%;
    height: calc(1.5em + .75rem + 2px);

    display: block;
    
    border: 1px solid #ced4da;

    border-top: 0px solid transparent;
    border-left: 0px solid transparent;
    border-right: 0px solid transparent;

    border-radius: 0px;

    padding: .375rem .75rem;

    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: white;

    background-color: transparent;
    background-clip: padding-box;

    box-shadow: none !important;

}

