
/**
* Workshop Input
**/
.WorkshopInput{
    border: none;
    background-color: #3E454D;
    color: white;
}

.InputCardBody{
    width: 100%;
    height: 100%;

    position: relative;

    margin: 0;
    padding: 0;

    display: block;
    text-align: center;
}

.InputCardTitle{
    color: white;
    background-color: #343a40;
}

.InputCardTitleText{
    font-weight: 500;
    font-size: 1.25rem;
}

.InputHeader{
    width: 97.5%;
    height: 5%;

    margin-left: 1.25%;
}


/**
* Input Card
**/
.InputCardUploadStatus, .InputCardUploadFiles{
    margin-top: 5%;
}

/**
* File Upload
**/

.InputCardUploadBody{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  
    width: 100%;

    text-align: center;
}


input[type="file"]{
    display: none;
}

.InputCardLogUpload{
    border: 1px solid #28a745;
    color: white;
    background-color: #28a745;

    padding: .375rem .75rem;

    border-radius: .25rem;
}

/** 
* Analysis / Upload Buttons
**/ 
.InputCardButtons{
    display: block;
    margin-top: 5%;

}

.InputCardUploadButton{
    margin-left: 1%;
    margin-right: 1%;
}

.WorkshopInputEditor{
    width:  97.5% !important;
    height: 90% !important;

    margin-left: 1.25%;
    margin-top: 1.25rem;
    overflow: auto;

    border: 0.75px solid rgba(0, 0, 0, 0.15);
    border-radius: 5px;
}


.UploadedFilesContainer{
    width: 90%;
    margin-left: 5%;

}


/**
* Code Editing theme
**/

.ace-twilight{
    background-color: rgba(0, 0, 0, 0.01);
    border-color: rgba(0, 0, 0, 0.4);
}
.ace-twilight .ace_gutter{
    background-color: rgba(0, 0, 0, 0.2);
}

.ace-twilight .ace_print-margin{
    background: transparent;
}