/**
*  The Main App Styling
**/
.App{
    width: 100%;
    height: 100%;

    display: block;
    position: absolute;
}


/**
* Our App Body
**/
.NavigationBar{
  position: relative !important;
  height: 50px;
  padding-left: 10px !important;
  justify-content: space-between !important;
}

.NavBarText{
  display: flex;
}

.NavBarTitle{
    color: white;
    font-weight: 500;
}

.NavBarSpacer{
  color: white;
  font-weight: 500;
  margin-left: 0.5em;
  margin-right: 0.5em;
}

.NavBarSubTitle{
  color: white;
  font-weight: 200;
}

.AppFrame{
  width: 100%;
  height: calc(100% - 50px);

  display: flex;
  position: absolute;
  
}

.AppBody{
    width: calc(100% - 60px);
    height: 100%;

    display: flex;
    position: relative;

    background-color: #454d54;

    overflow: auto;
}


/* width */
::-webkit-scrollbar {
    width: 7px;
    max-height: 7px;
}
  
  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: transparent;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
  }


/**
* Pop Over Styling | I gave up trying to make this work, so fuck it here it is
**/
.arrow::after{
  border: solid transparent !important;
}

.popover-header::before{
  border-bottom: 0px solid transparent !important;
}




body {  min-height: 750px; }