.MenuBar{
    width: 60px;
    height: 100%;
    background-color: #3E454D;
    position: relative;
    outline: none;
}

.InsightsLogo{
    width: auto;
    height: 50px;   
}

.MenuButton{
    width: auto;
    height: 50px;

    margin-top: 10%;
}

.InsightsPicture{
    width: 40px;
    height: 40px;

    margin-top: 7.5px;
    margin-left: 10px;
    user-select:none;

}

.DiscordLogo{
    width: 30px;
    height: 30px;

    margin-top: 10px;
    margin-left: 15px;
    user-select:none;

}

.MenuIcon{
    width: 30px;
    height: 30px;

    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: 15px;
    user-select:none;

}

.AlertIcon{
    bottom: 0;
    position: absolute;
}


.MenuDivider{
    width: 80%;

    margin-left: 10%;
    margin-bottom: 7.5px;
    margin-top: 7.5px;

    border-color: rgba(0, 0, 0, 0.2);

}