.SettingsComponent{
    width: 100%;
    height: 100%;
    
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 2.5%;

    background-color: #3E454D;
    color: white;
}

.TitleOfSettingsBox{
    margin-left: -2.5%;
    margin-bottom: -4%;
}

.SettingsBox{
    width: 100%;
    height: auto;
 
    
    margin-top: 5%;
}

.SettingsTitle{
    margin-top: 2%;
    margin-bottom: 0;
}

.SettingsDescriptionBox{
    display: flex;
}

.SettingsDescription{
    width: 70%;
}

.SettingsSwitch{
    width: 15%;
    
    margin-left: 20%;
}

.SettingsNumberInput{
    width: 5%;
    height: calc(1.5em + .75rem + 2px);

    margin-left: 15%;

    display: block;
    
    border: 1px solid #ced4da;

    border-top: 0px solid transparent;
    border-left: 0px solid transparent;
    border-right: 0px solid transparent;

    border-radius: 0px;

    padding: .375rem .75rem;

    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;

    background-color: transparent;
    background-clip: padding-box;

    outline: 0px solid transparent;


}


.SettingsNumberInput:active, .SettingsNumberInput:focus{
    width: 5%;
    height: calc(1.5em + .75rem + 2px);

    margin-left: 15%;

    display: block;
    
    border: 1px solid #ced4da;

    border-top: 0px solid transparent;
    border-left: 0px solid transparent;
    border-right: 0px solid transparent;

    border-radius: 0px;

    padding: .375rem .75rem;

    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;

    background-color: transparent;
    background-clip: padding-box;

    outline: 0px solid transparent;
    box-shadow: none !important;

}