/**
* Parent Modal
**/
.ConfigurationModal{
    width: 70%;
    height: 90%;

    max-width: none !important;
    max-height: none !important;

    border-radius: .25rem;

}

.ConfigurationCardTitle{
    font-weight: 500;
    color: white;
    background-color: #343a40;

}

.modal-content{
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    pointer-events: auto;
    background-color: #3E454D;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: .3rem;
    outline: 0;
}


/**
* Page Selection Pane
**/

.PagesPane{
    width: 20%;
    height: 100%;
    display: block;
    background-color: #343a40;

}

/**
* Button for selecting pages
**/
.PageButton{
    width: 100%;
    height: auto;

    border: 0px solid grey !important;
    border-radius: 0;
    background-color: #343a40;

    
    color: white;
    font-weight: 200;

    padding: .375rem .75rem;
    font-size: 1rem;
}

.PageButton:hover{
    background-color: rgba(0, 0, 0, 0.1);
}

.PageButton:active, .PageButton:focus{
    background-color: rgba(0, 0, 0, 0.1);
    outline: none;
}


/**
* Actual Data Display
**/
.ConfigurationPane{
    width: 80%;
    height: 100%;

    display: block;
}

.ConfigurationCard{
    width: 100%;
    height: 100%;
    border: 1px solid #343a40;
    border-radius: 5px;
}

.ConfigurationCardFooter{
    background-color: #3E454D;
}

.DirectionButtonDiv{
    width: 100%;
    height: 100%;
    justify-content: flex-end;

    display: flex;
}

.DirectionButtons{
    margin-right: 1%;
}