.ConfigureTeamInfoContainer{
    width: 100%;
    height: 100%;
    
    padding: 5%;
    padding-top: 2.5%;

    background-color: #3E454D;
    color: white;
}

.ConfigureTeamInfoTitle{
    margin-left: -2.5%;
}

.GlobalPlayersContainer{
    width: 100%;
    height: 40%;

    display: flow-root;

    overflow: auto;


    background-color: transparent;
}



.ConfigureTeamInfoHeaderText{
    margin-top: 2%;
}