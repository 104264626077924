.ServerOutput{
    border: none;
    background-color: #3E454D;
    color: white;
}

.InputCardTitle{
    color: white;
    background-color: #343a40;
}

.InputCardTitle{
    font-weight: 500;
    font-size: 1.25rem;
}
